import {
  registerApplication,
  start,
  LifeCycles,
  setMountMaxTime,
  setBootstrapMaxTime,
  pathToActiveWhen,
} from 'single-spa';
/**
 * Send specific, migrated routes to the Ionic application.
 */
const ionicPaths = [
  '/:marketSlug/theater/:theaterSlug',
  '/theater/:theaterSlug',
  '/:marketSlug/theater-bar/:theaterSlug',
  '/theater-bar/:theaterSlug',
  '/:marketSlug/bar-details/:theaterSlug',
  '/bar-details/:theaterSlug',

  '/victory/faqs',
  '/victory/seasonpass-faqs',
  '/victory/victory-rewards',
  '/victory/forgot-password',
  '/settings/forgot-password',
  '/victory/update-password',
  '/victory/reset-password/:resetCode',
  '/:marketSlug/only-at-the-alamo',
  '/only-at-the-alamo',
  '/:marketSlug/offers',
  '/offers',
  '/home',
];

function hideLoader() {
  document.getElementById('sspa-loading').style.display = 'none';
}

registerApplication({
  name: '@drafthouse/adc-website',
  app: async () => {
    const singleSpaCss = await System.import('singleSpaCss');
    const singleSpaAngularjs = await System.import('singleSpaAngularjs');

    window['singleSpaCss'] = singleSpaCss;
    window['singleSpaAngularjs'] = singleSpaAngularjs.default;

    /**
     * Why this bad coding style of having a global variable alamo-drafthouse--globals?
     * The legacy adc-website needs to know the set of all adc-ui-ionic routes. If not it'll try to render those
     * routes and load a 404 page finally.
     *
     * Shouldn't this adc-ui-sspa-root app handle the routings?
     * Ideally yes.  But the legacy adc-website is so huge that manytimes adc-ui-sspa-root couldn't stop the adc-website routing
     * juggernaut. And adc-website wins the routing race.
     *
     * The Single SPA documentation talks about inter app communication features. Why can't make the adc-website and adc-ui-ionic
     * talk in a formal manner and share the ionic paths? Instead of sharing information through window globals?
     * We're working on that.
     *
     * How exactly the legacy adc-website uses these adcIonicPaths?
     * When it loads with these path, the AngularJs creates an empty app module. This empty AngularJs app doesn't do
     * anything, specifically no routing will happen at its end.
     *
     */

    if (!window['alamo-drafthouse--globals']) {
      window['alamo-drafthouse--globals'] = {
        adcIonicPaths: [...ionicPaths],
      };
    }

    return await System.import<LifeCycles>('@drafthouse/adc-website');
  },
  activeWhen: (location) =>
    !ionicPaths.some((angularPath) => pathToActiveWhen(angularPath, true)(location)),
  customProps: (appName, location) => {
    return {
      isThisAppPath: !ionicPaths.some((angularPath) =>
        pathToActiveWhen(angularPath, true)(location),
      ),
    };
  },
});

registerApplication({
  name: '@drafthouse/adc-ui-ionic',
  app: () => System.import<LifeCycles>('@drafthouse/adc-ui-ionic'),
  activeWhen: (location) =>
    ionicPaths.some((angularPath) => pathToActiveWhen(angularPath, true)(location)),
});

start({
  urlRerouteOnly: true,
});

setMountMaxTime(60000, false, 70000);
setBootstrapMaxTime(60000, false, 70000);

window.addEventListener('single-spa:routing-event', () => {
  hideLoader();
  document.body.setAttribute('style', '');
});
window.addEventListener('single-spa:before-routing-event', () => {
  document.body.setAttribute('style', 'background: #090909');
});
